import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useGameContext } from '../contexts/Game';

const Scores = () => {
    const {
        loaded,
        interpState,
        name: playerName,
        setName: setPlayerName,
    } = useGameContext();

    const list = React.useMemo(() => {
        const scores = Object.entries(interpState) as [string, number][];
        scores.sort((a, b) => b[1] - a[1]);
        return scores;
    }, [interpState])

    const [changeNameOpen, setChangeNameOpen] = React.useState(false);
    const [tempNewName, setTempNewName] = React.useState('');

    React.useEffect(() => {
        setTempNewName(playerName || '');
    }, [playerName])

    const submitNameChange = React.useCallback(() => {
        const trimmedPlayerName = tempNewName.trim();
        if (trimmedPlayerName) {
            setPlayerName(trimmedPlayerName);
            setChangeNameOpen(false);
        }
    }, [setPlayerName, tempNewName]);

    if (!loaded) {
        return (
            <Typography>...</Typography>
        )
    }
    return (
        <Stack>
            <Box
                display="grid"
                columnGap="6px"
                gridTemplateColumns="auto auto"
                justifyContent="end"
                p="5px 10px"
            >
                {list.map(([name, score], index) => (
                    [
                        <Stack gridRow={index + 1} gridColumn={1} justifySelf="end" key={`${name}-score`} direction="row">
                            <Typography>
                                {name === playerName ? '👉 ' : null}
                                {score}
                            </Typography>
                        </Stack>,
                        <Stack gridRow={index + 1} gridColumn={2} justifySelf="start" alignItems="center" key={`${name}-name`} direction="row" gap="6px">
                            <Typography fontWeight={700}>
                                {name}
                            </Typography>
                            {name === playerName && (
                                <Typography
                                    sx={{
                                        cursor: 'pointer',
                                        userSelect: 'none',
                                    }}
                                    onClick={() => { setChangeNameOpen(true) }}
                                >
                                    ✍️
                                </Typography>
                            )}
                        </Stack>,
                    ]
                ))}
            </Box>
            <Dialog
                open={changeNameOpen}
                onClose={() => setChangeNameOpen(false)}
            >
                <DialogTitle>Become someone else</DialogTitle>
                <DialogContent>
                    <DialogContentText>{`Becoming someone else will not transfer any points earned under your current name "${playerName}". New points will be earned under the new name, and chat messages will be sent showing your new name as the sender immediately.`}</DialogContentText>
                    <TextField
                        sx={{
                            mt: '15px',
                        }}
                        label="New name"
                        fullWidth
                        value={tempNewName}
                        onChange={(event) => setTempNewName(event.target.value)}
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                submitNameChange();
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="warning" onClick={() => {
                        setTempNewName(playerName || '');
                        setChangeNameOpen(false);
                    }}>Abort</Button>
                    <Button disabled={!tempNewName} onClick={submitNameChange}>Change</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
};

export default Scores;
